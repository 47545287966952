import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

enum LifeSituationType {
    IntReception = '0', //my //Интернет приемная ФССП
    IpInfo = '1', //my //О ходе ИП
    Complain = '5', // Хочу пожаловаться на действия судебного пристава-исполнителя
    DisableAlimony = '6', // Хочу отменить выплату алиментов
    CancelDocument = '7', // Хочу прекратить исполнительное производство
    WantSendApplication = '8', // Хочу сообщить судебному приставу информацию по исполнительному производству
    Other = '9', // Иное
    ImposeRestrictions = '10', // Хочу наложить ограничения на должника
    Check = '11', // Хочу проверить правильность взыскания денежных средств с должника
    PayDebt = '12', // Должник оплатил задолженность
    CancelActions = '13', // Хочу отложить исполнительные действия или применение мер принудительного исполнения
    CancelCourt = '14', // Хочу отменить постановление судебного пристава об окончании исполнительного производства
    PutInWantedList = '15', // Хочу объявить в розыск ребенка, должника или его имущество
    GetDebtFromSalary = '16', // Хочу взыскать задолженность из заработной платы (пенсии, стипендии или других доходов) должника
    RefuseDocument = '17', // Отозвать исполнительный документ
    GetExpenses = '18', // Хочу взыскать с должника расходы на совершение исполнительных действий
    RepeatDocument = '19', // Хочу повторно передать исполнительный документ на принудительное исполнение в ФССП России
}

export const LifeSituationTypeList = [
    { id: LifeSituationType.IntReception, name: 'Интернет-приемная ФССП' },
    { id: LifeSituationType.IpInfo, name: 'Информация о ходе ИП' },
    { id: LifeSituationType.Complain, name:'Подать жалобу или заявление об отстранении пристава' }, //Хочу пожаловаться на действия судебного пристава-исполнителя
    { id: LifeSituationType.DisableAlimony, name:'Отменить выплату алиментов' }, //
    { id: LifeSituationType.CancelDocument, name:'Прекратить ИП' }, //Хочу прекратить исполнительное производство
    { id: LifeSituationType.WantSendApplication, name:'Подать ходатайство или объяснение' }, //Хочу сообщить судебному приставу информацию по исполнительному производству' },
    { id: LifeSituationType.ImposeRestrictions, name:'Наложить ограничения на должника' }, //Хочу наложить ограничения на должника
    //{ id:'10', name:'Наложить арест на имущество должника' }, //
    //{ id:'10', name:'Наложить ограничение на выезд должника из России' }, //
    //{ id:'10', name:'Ограничить право управления транспортным средством' }, //
    { id: LifeSituationType.Check, name:'Проверить правильность взыскания с доходов должника' }, //Хочу проверить правильность взыскания денежных средств с должника
    { id: LifeSituationType.PayDebt, name:'Сообщить о погашении задолженности' }, //Должник оплатил задолженность' },
    { id: LifeSituationType.CancelActions, name:'Отложить исполнительные действия и меры принудительного исполнения' }, //Хочу отложить исполнительные действия или применение мер принудительного исполнения
    { id: LifeSituationType.CancelCourt, name:'Отменить постановление об окончании ИП' }, //Хочу отменить постановление судебного пристава об окончании исполнительного производства "
    { id: LifeSituationType.PutInWantedList, name:'Начать розыск' }, //
    { id: LifeSituationType.GetDebtFromSalary, name:'Обратить взыскание на доходы должника' }, //Хочу взыскать задолженность из заработной платы (пенсии, стипендии или других доходов) должника
    { id: LifeSituationType.RefuseDocument, name:'Подать заявление об окончании ИП' }, //Отозвать исполнительный документ
    { id: LifeSituationType.GetExpenses, name:'Взыскать расходы на исполнительные действия' }, //Хочу взыскать с должника расходы на совершение исполнительных действий
    { id: LifeSituationType.RepeatDocument, name:'Повторно предъявить исполнительный документ' }, //Хочу повторно передать исполнительный документ на принудительное исполнение в ФССП России
]

const LifeSituationKindList = [
    { id:'51', name:'Обжаловать постановлением пристава' },
    { id:'52', name:'Подать жалобу на действия или бездействие пристава' },
    { id:'54', name:'Подать заявление об отстранении пристава' },
    { id:'82', name:'Ходатайство' },
    { id:'83', name:'Объяснение' },
    { id:'101', name:'Наложить арест на имущество должника' },
    { id:'102', name:'Наложить ограничение на выезд должника из России' },
    { id:'103', name:'Ограничить право управления транспортным средством' },
    { id:'151', name:'Ребенка' },
    { id:'152', name:'Должника' },
    { id:'153', name:'Имущество должника' },
];

const lifeSituationName = (record) => (
    (!record.lifeSituationType ? '' : LifeSituationTypeList.find((ls) => ls.id === record.lifeSituationType)?.name || record.lifeSituationType) + 
     (!record.lifeSituationKind ? '' : ' - ' + LifeSituationKindList.find((lk) => lk.id === record.lifeSituationKind)?.name || record.lifeSituationKind)
);

const LifeSituationField = ({
    source, record = {}
  }) =>
    <Typography variant={'body2'} >{lifeSituationName(record)}</Typography>

LifeSituationField.propTypes = {
    label: PropTypes.string,
    addLabel: PropTypes.bool,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default LifeSituationField;